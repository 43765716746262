'use client';

import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import React from 'react';

type GameLinkWrapperProps = {
  url: string;
  campaignDetails: string;
};

export default function GameLinkWrapper({
  children,
  url,
  campaignDetails,
}: React.PropsWithChildren<GameLinkWrapperProps>) {
  const biAction = useBi();

  return (
    <HtzLink
      href={url}
      onClick={() =>
        biAction({
          page_type: 'Error',
          action_id: 109,
          campaign_name: 'error',
          feature: 'riddles list',
          campaign_details: campaignDetails,
        })
      }
    >
      {children}
    </HtzLink>
  );
}
